import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import React from "react";
import Referral from "./../@narative/gatsby-theme-novela/components/Referral";

const headingText = {
  main: "Better get Mum some of that leverage.",
  sub: "Just you? Share your love for the daily views and news to help you build a better tech future",
  subSub: "Invite your friend" 
} 

function InvitePage() {
  return (
    <Layout>
      <SEO />
      <Section>
        <div style={{ marginTop: "100px" }}>
          <Headings.h1>{headingText.main}</Headings.h1>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Headings.h3>{headingText.sub}</Headings.h3>
        </div>
      
        <div style={{ marginTop: "50px" }}>
        <Referral />
        </div>
      
      </Section>
    </Layout>
  );
}

export default InvitePage;
